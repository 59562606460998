import { getLocalAssetFromCDN } from '@helpers'

import { Container } from '@lib/ui'

import {
  StyledBiggerTitle,
  StyledList,
  StyledListItem,
  StyledListItemText,
  StyledListItemTitle,
  StyledMap,
  StyledMapContainer
} from './MapSection.styled'

const data = [
  {
    title: 'Catforth',
    text: 'In the Lancashire Countryside'
  },
  {
    title: 'FULLY FURNISHED',
    text: 'All furniture included'
  },
  {
    title: '£900,000',
    text: 'The value of the mansion',
    isBiggerText: true
  }
]

const MapSection = () => {
  return (
    <Container>
      <StyledMapContainer>
        <StyledList>
          {data.map(({ title, text, isBiggerText }, index) => (
            <StyledListItem key={index}>
              {isBiggerText ? (
                <div>
                  <StyledBiggerTitle>{title}</StyledBiggerTitle>
                  <StyledListItemText>{text}</StyledListItemText>
                </div>
              ) : (
                <>
                  <StyledListItemTitle>{title}</StyledListItemTitle>
                  <StyledListItemText>{text}</StyledListItemText>
                </>
              )}
            </StyledListItem>
          ))}
        </StyledList>
        <StyledMap>
          <img src={getLocalAssetFromCDN('/assets/img/landing/map.webp')} />
        </StyledMap>
      </StyledMapContainer>
    </Container>
  )
}

export default MapSection
