import { css, styled } from '@mui/material'

export const StyledMainTabsHolder = styled('div')`
  display: flex;
  justify-content: center;
  background: rgba(240, 240, 240, 0.6);
  border-radius: 12px;
  padding: 4px;

  @media screen and (max-width: 500px) {
    width: 100%;
  }
`

export const StyledTabs = styled('div')`
  display: flex;
  gap: 10px;

  @media screen and (max-width: 500px) {
    font-size: 36px;
    line-height: 44px;
    line-height: 44px;
    justify-content: center;
    gap: 0;
    width: 100%;
  }
`

export const StyledTabItem = styled('button', {
  shouldForwardProp: propName => propName !== 'isActive'
})<{
  isActive?: boolean
}>`
  background: transparent;
  cursor: pointer;
  color: var(--Neutral8);
  border-radius: 10px;
  border: none;
  font-size: 20px;
  line-height: 26px;
  padding: 10px 16px;

  &:hover {
    background: rgba(255, 255, 255, 0.6);
  }

  @media screen and (max-width: 500px) {
    font-size: 16px;
    line-height: 22px;
    width: 50%;
  }

  ${({ isActive = false }) => {
    if (isActive) {
      return css`
        background: var(--Neutral1);

        &:hover {
          background: var(--Neutral1);
        }
      `
    }
  }}
`
