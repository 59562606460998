import { styled } from '@mui/material'

export const StyledWrapper = styled('div')`
  background: linear-gradient(
    91.46deg,
    #ebef3f 0%,
    #e1010b 49.99%,
    #480000 100%
  );
`

export const StyledWalkLaneContainer = styled('div')`
  font-family: 'Orbitron';
  text-transform: uppercase;
  padding: 12px 0;
  white-space: nowrap;
  font-size: 24px;
  line-height: 32px;
  font-style: normal;
  font-weight: 600;
  user-select: none;
  display: flex;
  align-items: center;
`

export const StyledWalkLaneText = styled('div')`
  margin: 0 24px;
`
