import { styled } from '@mui/material'

export const StyledGeneralInfoWrapper = styled('div')`
  display: flex;
  padding: 116px 0;
  gap: 10px;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }

  @media screen and (max-width: 800px) {
    padding: 40px 0;
  }

  @media screen and (max-width: 600px) {
    padding: 50px 0;
  }
`

export const StyledImagesBlock = styled('div')`
  width: 50%;
  margin: 0 auto;

  & img {
    width: 100%;
  }

  @media screen and (max-width: 700px) {
    width: 100%;
    margin: 0 0 10px;
  }
`

export const StyledInfoBlock = styled('div')`
  width: 50%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  @media screen and (max-width: 1024px) {
    align-items: center;
    width: 100%;
  }
`

export const StyledInfoContent = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 16px;
  background: var(--Neutral8);
  border-radius: 12px;
  align-items: flex-start;

  @media screen and (max-width: 768px) {
    padding: 12px;
  }

  @media screen and (max-width: 400px) {
    padding: 6px;
  }
`

export const StyledSquareList = styled('div')`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 8px;
`

export const StyledSquareItem = styled('div')`
  display: flex;
  width: calc(33% - 4px);
  flex-shrink: 0;
  align-items: center;
  white-space: nowrap;
  padding: 12px;
  border: 1px solid var(--Neutral5);
  font-size: 28px;
  line-height: 34px;
  border-radius: 12px;
  gap: 12px;

  & * {
    flex-shrink: 0;
  }

  @media screen and (max-width: 1200px) {
    width: calC(50% - 4px);
  }

  @media screen and (max-width: 768px) {
    font-size: 24px;
    line-height: 32px;
  }

  @media screen and (max-width: 500px) {
    width: calC(42% - 4px);
    flex-grow: 1;

    &:nth-of-type(even) {
      width: calC(58% - 4px);
    }
  }
`

export const StyledSquareTextContent = styled('div')`
  display: flex;
  align-items: baseline;
  gap: 4px;
  font-weight: 600;
`

export const StyledSquareText = styled('span')`
  font-size: 16px;
  line-height: 22px;
  color: var(--Neutral2);
  font-weight: 500;
`

export const StyledButtonHolder = styled('div')`
  @media screen and (max-width: 700px) {
    width: 100%;
  }
`
