import { styled } from '@mui/material'

export const StyledWinnersSectionWrapper = styled('div')`
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`

export const StyledImageDesktop = styled('div')`
  @media screen and (max-width: 550px) {
    display: none;
  }
`

export const StyledImageMobile = styled('div')`
  display: none;

  @media screen and (max-width: 550px) {
    display: block;
  }
`
