import { useEffect, useRef, useState } from 'react'

import VillaTimer from '../VillaTimer'

import { StyledHolder } from './FixedVillaTimer.styled'
import { type IFixedVillaTimerProps } from './FixedVillaTimer.types'

const FixedVillaTimer = (props: IFixedVillaTimerProps) => {
  const { screenRef } = props

  const [bannerPosition, setBannerPosition] = useState(100)

  const bannerRef = useRef<HTMLDivElement | null>(null)

  const handleScroll = () => {
    if (screenRef.current && bannerRef.current) {
      const firstScreenHeight = screenRef.current.offsetHeight
      const scrollY = window.scrollY || window.pageYOffset

      const scrolledPastFirstScreen = scrollY - firstScreenHeight

      if (scrolledPastFirstScreen > 0) {
        const bannerHeight = bannerRef.current.offsetHeight
        const maxLift = bannerHeight - 10
        const visibleBannerPart = Math.min(scrolledPastFirstScreen, maxLift)

        const bannerPosition = Math.max(
          0,
          100 - (visibleBannerPart / maxLift) * 100
        )
        setBannerPosition(bannerPosition)
      } else {
        setBannerPosition(100)
      }
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <StyledHolder
      ref={bannerRef}
      style={{
        transform: `translateY(${bannerPosition}%)`
      }}
    >
      <VillaTimer />
    </StyledHolder>
  )
}

export default FixedVillaTimer
