import { styled } from '@mui/material'

export const StyledAdvantagesWrapper = styled('div')`
  display: flex;
  gap: 16px;
  margin: 0 0 116px;

  @media screen and (max-width: 1170px) {
    flex-wrap: wrap;
  }

  @media screen and (max-width: 800px) {
    margin: 0 0 40px;
  }
`

export const StyledAdvantagesHolder = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  flex-grow: 1;
`

export const StyledAdvantagesTextHolder = styled('div')`
  display: flex;
  padding: 16px 12px;
  align-items: flex-start;
  flex-grow: 1;
  background: var(--Neutral7);
  border-radius: 12px;
`
export const StyledAdvantagesTextBlock = styled('div')`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
`

export const StyledAdvantagesTitle = styled('div')`
  font-family: 'Orbitron';
  font-size: 48px;
  line-height: 56px;
  font-weight: 600;
  background: linear-gradient(91.46deg, #ebef3f 0%, #e1010b 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media screen and (max-width: 600px) {
    font-size: 36px;
    line-height: 44px;
  }
`

export const StyledAdvantagesSubtitle = styled('div')`
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  color: var(--Neutral1);
`

export const StyledAdvantagesImageHolder = styled('div')`
  height: 400px;
  border-radius: 12px;
  overflow: hidden;

  @media screen and (max-width: 600px) {
    height: 200px;
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`
