import { styled } from '@mui/material'

export const StyledInfoTitle = styled('h3')`
  font-family: 'Orbitron';
  color: var(--Neutral1);
  font-size: 24px;
  line-height: 32px;
  text-transform: uppercase;
  border: 1px solid var(--Neutral1);
  padding: 12px;
  border-radius: 8px;
  display: inline-flex;
  margin: 0;

  @media screen and (max-width: 600px) {
    font-size: 20px;
    line-height: 26px;

    & br {
      display: none;
    }
  }
`
