import { Container } from '@lib/ui'

import { getLocalAssetFromCDN } from '@helpers'

import {
  StyledImageDesktop,
  StyledImageMobile,
  StyledWinnersSectionWrapper
} from './WinnerSection.styled'
import { StyledInfoTitle } from '@features/villaLanding/InfoTitle/InfoTitle.styled'

const WinnersSection = () => {
  return (
    <Container>
      <StyledWinnersSectionWrapper>
        <StyledInfoTitle>our latest property winners</StyledInfoTitle>
        <StyledImageDesktop>
          <img
            src={getLocalAssetFromCDN(
              '/assets/img/landing/winners-desktop.png'
            )}
            width="100%"
          />
        </StyledImageDesktop>
        <StyledImageMobile>
          <img
            src={getLocalAssetFromCDN('/assets/img/landing/winners-mobile.png')}
            width="100%"
          />
        </StyledImageMobile>
      </StyledWinnersSectionWrapper>
    </Container>
  )
}

export default WinnersSection
