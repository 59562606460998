import { styled } from '@mui/material'

export const StyledCarouselWrapper = styled('div')`
  margin: 0 0 60px;

  @media screen and (max-width: 800px) {
    margin: 0 0 40px;
  }
`

export const StyledCarouselImage = styled('div')`
  width: 100%;
  height: 100%;
  border-radius: 16px;
  overflow: hidden;
  margin: 0 0 16px;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 800px) {
    margin: 0 0 40px;
  }

  @media screen and (max-width: 600px) {
    height: 300px;
  }
`

export const StyledCarouselContent = styled('div')`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  margin: 0 auto;
  max-width: 740px;
  text-align: center;
`
