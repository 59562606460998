import { useCallback } from 'react'

import { DateTime, Duration } from 'luxon'

import { Countdown } from '@lib/ui'

import {
  StyledTimer,
  StyledTimerItem,
  StyledTimerText
} from './VillaTimer.styled'

const VillaTimer = () => {
  const renderCountdown = useCallback(
    (countDown: Duration, addLeadingZeros: (value: number) => string) => {
      return (
        <StyledTimer>
          <StyledTimerItem>
            <StyledTimerText suppressHydrationWarning>
              {addLeadingZeros(countDown.days)}
            </StyledTimerText>
            d
          </StyledTimerItem>
          <StyledTimerItem>
            <StyledTimerText suppressHydrationWarning>
              {addLeadingZeros(countDown.hours)}
            </StyledTimerText>
            h
          </StyledTimerItem>
          <StyledTimerItem>
            <StyledTimerText suppressHydrationWarning>
              {addLeadingZeros(countDown.minutes)}
            </StyledTimerText>
            m
          </StyledTimerItem>
          <StyledTimerItem>
            <StyledTimerText suppressHydrationWarning>
              {addLeadingZeros(countDown.seconds)}
            </StyledTimerText>
            s
          </StyledTimerItem>
        </StyledTimer>
      )
    },
    []
  )

  return (
    <Countdown
      renderCountdown={renderCountdown}
      date={DateTime.fromISO('2024-12-31T17:00:00.000Z', {
        zone: 'Europe/London'
      })}
    />
  )
}

export default VillaTimer
