import { styled } from '@mui/material'

export const StyledGradientText = styled('span')`
  font-family: 'Orbitron';
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: linear-gradient(91.46deg, #e1010b 0%, #480000 100%);
  font-size: 24px;
  line-height: 32px;
  padding: 12px 22px;
  text-transform: uppercase;
  font-weight: 600;
  border-radius: 12px;
  letter-spacing: 1px;
  gap: 12px;
  flex-grow: 1;

  @media screen and (max-width: 500px) {
    font-size: 17px;
    line-height: 24px;
  }
`

export const GradientTextBannerSubtitle = styled('span')`
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  line-height: 26px;
  font-weight: 400;
  text-transform: none;

  @media screen and (max-width: 500px) {
    font-size: 20px;
    line-height: 26px;
  }
`
