import { styled } from '@mui/material'

export const StyledTimer = styled('div')`
  font-family: 'Poppins';
  display: inline-flex;
  background-color: var(--Secondary);
  padding: 9px 36px;
  border-radius: 12px;
`

export const StyledTimerItem = styled('div')`
  margin: 0 10px;
  font-size: 20px;
  line-height: 24px;
  display: inline-flex;
  align-items: baseline;
`

export const StyledTimerText = styled('span')`
  font-weight: 500;
  margin-right: 2px;
  font-size: 24px;
  line-height: 30px;
`
