import { styled } from '@mui/material'

export const StyledMapContainer = styled('div')`
  display: flex;
  gap: 16px;
  margin: 60px 0;

  @media screen and (max-width: 1024px) {
    align-items: flex-start;
  }

  @media screen and (max-width: 800px) {
    flex-direction: column;
    margin: 40px 0 40px;
  }
`

export const StyledList = styled('div')`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  width: 50%;

  @media screen and (max-width: 800px) {
    width: 100%;
  }
`

export const StyledListItem = styled('div')`
  flex-basis: calc(50% - 8px);
  border-radius: 12px;
  border: 1px solid var(--Neutral1);
  padding: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 12px;
  flex-grow: 1;

  @media screen and (max-width: 1024px) {
    flex-basis: 100%;
  }

  @media screen and (max-width: 800px) {
    flex-basis: calc(50% - 8px);
  }

  @media screen and (max-width: 500px) {
    flex-basis: 100%;
    padding: 16px 12px;
  }
`

export const StyledListItemTitle = styled('div')`
  font-family: 'Orbitron';
  font-weight: 500;
  color: var(--ThirdVariant);
  font-size: 24px;
  line-height: 32px;
  text-transform: uppercase;

  @media screen and (max-width: 500px) {
    font-size: 20px;
    line-height: 26px;
  }
`

export const StyledBiggerTitle = styled('div')`
  font-family: 'Orbitron';
  font-size: 48px;
  line-height: 56px;
  font-weight: 600;
  color: var(--ThirdVariant);

  @media screen and (max-width: 600px) {
    font-size: 36px;
    line-height: 44px;
  }
`

export const StyledListItemText = styled('p')`
  font-size: 20px;
  line-height: 26px;
  margin: 0;

  @media screen and (max-width: 500px) {
    font-size: 17px;
    line-height: 24px;
  }
`

export const StyledMap = styled('div')`
  display: flex;
  align-items: flex-start;
  width: 50%;
  border-radius: 12px;
  overflow: hidden;

  & img {
    width: 100%;
  }

  @media screen and (max-width: 800px) {
    width: 100%;
  }
`
