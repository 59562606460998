import 'swiper/css'
import 'swiper/css/effect-coverflow'
import 'swiper/css/pagination'

import { EffectCoverflow, Navigation, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import Typography from '@lib/ui/Typography'

import { getLocalAssetFromCDN } from '@helpers'

import { StyledInfoTitle } from '../InfoTitle/InfoTitle.styled'

import {
  StyledCarouselContent,
  StyledCarouselImage,
  StyledCarouselWrapper
} from './Carousel.styled'

const carouselData = [
  {
    title: 'Your Dream Home',
    subtitle:
      'Featuring 5 bedrooms, 4 bathrooms and open planned living your dream home awaits.',
    imgUrl: '/assets/img/landing/carousel-1.webp'
  },
  {
    title: 'Countryside Views',
    subtitle:
      'Wake up to stunning countryside views stretching out from both the front and back, adding a dash of magic to start every day.',
    imgUrl: '/assets/img/landing/carousel-2.webp'
  },
  {
    title: 'The Garden',
    subtitle:
      'The rear exterior of this new-build mansion features modern design while the huge garden offers plenty of space for enjoying the outdoors',
    imgUrl: '/assets/img/landing/carousel-3.webp'
  },
  {
    title: 'Open Planned Living',
    subtitle:
      'Enjoy a stunning open-plan kitchen and living space made for relaxing, hosting, and everything in between (including hiding from the in-laws)!',
    imgUrl: '/assets/img/landing/carousel-4.webp'
  },
  {
    title: 'The Master Bedroom',
    subtitle:
      'Fantastic views, high vaulted ceilings, and a separate dressing room leading to a magnificent ensuite—this master bedroom is fit for a king or queen!',
    imgUrl: '/assets/img/landing/carousel-5.webp'
  },
  {
    title: 'The Main Bathroom',
    subtitle:
      'Step into a family bathroom that feels like a luxury hotel spa—sleek finishes, soft lighting, and premium fixtures make every moment feel like a five-star experience.',
    imgUrl: '/assets/img/landing/carousel-6.webp'
  },
  {
    title: 'The Kitchen',
    subtitle:
      'Gordan Ramsey eat your heart! This showstopping kitchen is centered around a stunning stone island—perfect for cooking, gathering, and making every meal feel special.',
    imgUrl: '/assets/img/landing/carousel-7.webp'
  },
  {
    title: 'Home Office',
    subtitle:
      'A warm, inviting office space designed for focus and comfort, with soft lighting and snug seating that makes working feel like home.',
    imgUrl: '/assets/img/landing/carousel-8.webp'
  },
  {
    title: 'Family Lounge',
    subtitle:
      "A comfy family lounge that's perfect for movie nights, with soft couches and a cozy vibe that makes it feel like the ultimate hangout spot.",
    imgUrl: '/assets/img/landing/carousel-9.webp'
  }
]

const Carousel = () => {
  return (
    <StyledCarouselWrapper>
      <Swiper
        effect="coverflow"
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={1.3}
        spaceBetween={10}
        loop={true}
        coverflowEffect={{
          rotate: 60,
          stretch: 0,
          depth: 200,
          modifier: 1,
          slideShadows: true
        }}
        pagination={{
          clickable: true,
          dynamicBullets: true
        }}
        breakpoints={{
          640: {
            slidesPerView: 1.2,
            spaceBetween: 20
          },
          768: {
            slidesPerView: 1.2,
            spaceBetween: 20
          },
          1024: {
            slidesPerView: 1.6,
            spaceBetween: 130
          }
        }}
        modules={[Navigation, Pagination, EffectCoverflow]}
        className="swiper-carousel"
        style={{ paddingBottom: '60px' }}
      >
        {carouselData.map(({ title, subtitle, imgUrl }, index) => (
          <SwiperSlide key={index}>
            <StyledCarouselImage>
              <img src={getLocalAssetFromCDN(imgUrl)} />
            </StyledCarouselImage>

            <StyledCarouselContent>
              <StyledInfoTitle>{title}</StyledInfoTitle>

              <Typography
                color="var(--Neutral3)"
                fontVariant="body-1"
                weight="regular"
              >
                {subtitle}
              </Typography>
            </StyledCarouselContent>
          </SwiperSlide>
        ))}
      </Swiper>
    </StyledCarouselWrapper>
  )
}

export default Carousel
