import { styled } from '@mui/material'

export const StyledMainSection = styled('section')`
  position: relative;
  height: 100vh;
  min-height: 500px;

  @media screen and (max-width: 991px) {
    height: 100vh;
  }
`

export const StyledHandlersBlock = styled('div')`
  position: absolute;
  left: 4px;
  right: 4px;
  bottom: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  z-index: 1;

  @media screen and (max-width: 500px) {
    bottom: 6px;
  }
`

export const StyledMainTabsContent = styled('div')`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  width: 100%;
`
