import { useCallback } from 'react'

import 'swiper/css'
import 'swiper/css/navigation'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules'

import { Button } from '@lib/ui'

import { getLocalAssetFromCDN } from '@helpers'

import {
  StyledCarouselArrow,
  StyledContentBlock,
  StyledMainGradientText,
  StyledMainImage,
  StyledSliderImageWithMob
} from './MainTabs.styled'
import { type IMainTabsProps } from './MainTabs.types'

const MainTabs = (props: IMainTabsProps) => {
  const { currentTab, slugUrl } = props

  const handleOpenCompetitionDetails = useCallback(() => {
    window.location.href = `https://elitecompetitions.co.uk/competitions/${slugUrl}`
  }, [slugUrl])

  if (currentTab === 'Tab1') {
    return (
      <>
        <StyledContentBlock>
          <StyledMainGradientText>LUXURY HOUSE</StyledMainGradientText>
          <Button
            variant="primary"
            width="fit-content"
            size="large"
            onClick={handleOpenCompetitionDetails}
          >
            Enter now
          </Button>
        </StyledContentBlock>

        <StyledMainImage>
          <video autoPlay muted loop playsInline preload="none">
            <source
              src={getLocalAssetFromCDN('/assets/img/landing/video.mp4')}
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>

          <img
            src={getLocalAssetFromCDN('/assets/img/landing/gallery-1.webp')}
          />
        </StyledMainImage>
      </>
    )
  } else if (currentTab === 'Tab2') {
    return (
      <>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          loop={true}
          navigation={{
            nextEl: '#carousel-1-next',
            prevEl: '#carousel-1-prev'
          }}
          modules={[Navigation]}
        >
          <SwiperSlide>
            <StyledSliderImageWithMob>
              <img
                src={getLocalAssetFromCDN('/assets/img/landing/gallery-1.webp')}
              />

              <img
                src={getLocalAssetFromCDN(
                  '/assets/img/landing/gallery-mob-1.webp'
                )}
              />
            </StyledSliderImageWithMob>
          </SwiperSlide>

          <SwiperSlide>
            <StyledSliderImageWithMob>
              <img
                src={getLocalAssetFromCDN('/assets/img/landing/gallery-2.webp')}
              />

              <img
                src={getLocalAssetFromCDN(
                  '/assets/img/landing/gallery-mob-2.webp'
                )}
              />
            </StyledSliderImageWithMob>
          </SwiperSlide>

          <SwiperSlide>
            <StyledSliderImageWithMob>
              <img
                src={getLocalAssetFromCDN('/assets/img/landing/gallery-3.webp')}
              />

              <img
                src={getLocalAssetFromCDN(
                  '/assets/img/landing/gallery-mob-3.webp'
                )}
              />
            </StyledSliderImageWithMob>
          </SwiperSlide>

          <SwiperSlide>
            <StyledSliderImageWithMob>
              <img
                src={getLocalAssetFromCDN('/assets/img/landing/gallery-4.webp')}
              />

              <img
                src={getLocalAssetFromCDN(
                  '/assets/img/landing/gallery-mob-4.webp'
                )}
              />
            </StyledSliderImageWithMob>
          </SwiperSlide>

          <SwiperSlide>
            <StyledSliderImageWithMob>
              <img
                src={getLocalAssetFromCDN('/assets/img/landing/gallery-5.webp')}
              />

              <img
                src={getLocalAssetFromCDN(
                  '/assets/img/landing/gallery-mob-5.webp'
                )}
              />
            </StyledSliderImageWithMob>
          </SwiperSlide>

          <SwiperSlide>
            <StyledSliderImageWithMob>
              <img
                src={getLocalAssetFromCDN('/assets/img/landing/gallery-6.webp')}
              />

              <img
                src={getLocalAssetFromCDN(
                  '/assets/img/landing/gallery-mob-6.webp'
                )}
              />
            </StyledSliderImageWithMob>
          </SwiperSlide>

          <SwiperSlide>
            <StyledSliderImageWithMob>
              <img
                src={getLocalAssetFromCDN('/assets/img/landing/gallery-7.webp')}
              />

              <img
                src={getLocalAssetFromCDN(
                  '/assets/img/landing/gallery-mob-7.webp'
                )}
              />
            </StyledSliderImageWithMob>
          </SwiperSlide>

          <SwiperSlide>
            <StyledSliderImageWithMob>
              <img
                src={getLocalAssetFromCDN('/assets/img/landing/gallery-8.webp')}
              />

              <img
                src={getLocalAssetFromCDN(
                  '/assets/img/landing/gallery-mob-8.webp'
                )}
              />
            </StyledSliderImageWithMob>
          </SwiperSlide>

          <SwiperSlide>
            <StyledSliderImageWithMob>
              <img
                src={getLocalAssetFromCDN('/assets/img/landing/gallery-9.webp')}
              />

              <img
                src={getLocalAssetFromCDN(
                  '/assets/img/landing/gallery-mob-9.webp'
                )}
              />
            </StyledSliderImageWithMob>
          </SwiperSlide>

          <SwiperSlide>
            <StyledSliderImageWithMob>
              <img
                src={getLocalAssetFromCDN(
                  '/assets/img/landing/gallery-10.webp'
                )}
              />

              <img
                src={getLocalAssetFromCDN(
                  '/assets/img/landing/gallery-mob-10.webp'
                )}
              />
            </StyledSliderImageWithMob>
          </SwiperSlide>

          <SwiperSlide>
            <StyledSliderImageWithMob>
              <img
                src={getLocalAssetFromCDN(
                  '/assets/img/landing/gallery-11.webp'
                )}
              />

              <img
                src={getLocalAssetFromCDN(
                  '/assets/img/landing/gallery-mob-11.webp'
                )}
              />
            </StyledSliderImageWithMob>
          </SwiperSlide>

          <SwiperSlide>
            <StyledSliderImageWithMob>
              <img
                src={getLocalAssetFromCDN(
                  '/assets/img/landing/gallery-12.webp'
                )}
              />

              <img
                src={getLocalAssetFromCDN(
                  '/assets/img/landing/gallery-mob-12.webp'
                )}
              />
            </StyledSliderImageWithMob>
          </SwiperSlide>
          <SwiperSlide>
            <StyledSliderImageWithMob>
              <img
                src={getLocalAssetFromCDN(
                  '/assets/img/landing/gallery-13.webp'
                )}
              />

              <img
                src={getLocalAssetFromCDN(
                  '/assets/img/landing/gallery-mob-13.webp'
                )}
              />
            </StyledSliderImageWithMob>
          </SwiperSlide>
          <SwiperSlide>
            <StyledSliderImageWithMob>
              <img
                src={getLocalAssetFromCDN(
                  '/assets/img/landing/gallery-14.webp'
                )}
              />

              <img
                src={getLocalAssetFromCDN(
                  '/assets/img/landing/gallery-mob-14.webp'
                )}
              />
            </StyledSliderImageWithMob>
          </SwiperSlide>
          <SwiperSlide>
            <StyledSliderImageWithMob>
              <img
                src={getLocalAssetFromCDN(
                  '/assets/img/landing/gallery-15.webp'
                )}
              />

              <img
                src={getLocalAssetFromCDN(
                  '/assets/img/landing/gallery-mob-15.webp'
                )}
              />
            </StyledSliderImageWithMob>
          </SwiperSlide>
          <SwiperSlide>
            <StyledSliderImageWithMob>
              <img
                src={getLocalAssetFromCDN(
                  '/assets/img/landing/gallery-16.webp'
                )}
              />

              <img
                src={getLocalAssetFromCDN(
                  '/assets/img/landing/gallery-mob-16.webp'
                )}
              />
            </StyledSliderImageWithMob>
          </SwiperSlide>
          <SwiperSlide>
            <StyledSliderImageWithMob>
              <img
                src={getLocalAssetFromCDN(
                  '/assets/img/landing/gallery-17.webp'
                )}
              />

              <img
                src={getLocalAssetFromCDN(
                  '/assets/img/landing/gallery-mob-17.webp'
                )}
              />
            </StyledSliderImageWithMob>
          </SwiperSlide>
          <SwiperSlide>
            <StyledSliderImageWithMob>
              <img
                src={getLocalAssetFromCDN(
                  '/assets/img/landing/gallery-18.webp'
                )}
              />

              <img
                src={getLocalAssetFromCDN(
                  '/assets/img/landing/gallery-mob-18.webp'
                )}
              />
            </StyledSliderImageWithMob>
          </SwiperSlide>
          <SwiperSlide>
            <StyledSliderImageWithMob>
              <img
                src={getLocalAssetFromCDN(
                  '/assets/img/landing/gallery-19.webp'
                )}
              />

              <img
                src={getLocalAssetFromCDN(
                  '/assets/img/landing/gallery-mob-19.webp'
                )}
              />
            </StyledSliderImageWithMob>
          </SwiperSlide>
          <SwiperSlide>
            <StyledSliderImageWithMob>
              <img
                src={getLocalAssetFromCDN(
                  '/assets/img/landing/gallery-20.webp'
                )}
              />

              <img
                src={getLocalAssetFromCDN(
                  '/assets/img/landing/gallery-mob-20.webp'
                )}
              />
            </StyledSliderImageWithMob>
          </SwiperSlide>
          <SwiperSlide>
            <StyledSliderImageWithMob>
              <img
                src={getLocalAssetFromCDN(
                  '/assets/img/landing/gallery-21.webp'
                )}
              />

              <img
                src={getLocalAssetFromCDN(
                  '/assets/img/landing/gallery-mob-21.webp'
                )}
              />
            </StyledSliderImageWithMob>
          </SwiperSlide>
        </Swiper>

        <StyledCarouselArrow className="prev-button" id="carousel-1-prev">
          <img src={getLocalAssetFromCDN('/assets/img/landing/arrow.svg')} />
        </StyledCarouselArrow>

        <StyledCarouselArrow
          className="next-button"
          id="carousel-1-next"
          isRight={true}
        >
          <img src={getLocalAssetFromCDN('/assets/img/landing/arrow.svg')} />
        </StyledCarouselArrow>
      </>
    )
  }

  return null
}

export default MainTabs
