import { useState } from 'react'

import VillaTimer from '../VillaTimer'
import MainTabButtons from '../MainTabButtons'
import MainTabs from '../MainTabs'

import {
  StyledHandlersBlock,
  StyledMainSection,
  StyledMainTabsContent
} from './MainSection.styled'
import {
  type IVillaMainSectionProps,
  type MainSectionTabs
} from './MainSection.types'

const VillaMainSection = (props: IVillaMainSectionProps) => {
  const { slugUrl } = props

  const [currentTab, setCurrentTab] = useState<MainSectionTabs>('Tab1')

  return (
    <StyledMainSection>
      <StyledMainTabsContent>
        <MainTabs slugUrl={slugUrl} currentTab={currentTab} />
      </StyledMainTabsContent>

      <StyledHandlersBlock>
        <VillaTimer />

        <MainTabButtons currentTab={currentTab} setCurrentTab={setCurrentTab} />
      </StyledHandlersBlock>
    </StyledMainSection>
  )
}

export default VillaMainSection
