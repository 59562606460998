import { StyledGradientText } from './GradientTextBanner.styled'
import { type IGradientTextBannerProps } from './GradientTextBanner.types'

const GradientTextBanner = (props: IGradientTextBannerProps) => {
  const { children, ...otherProps } = props

  return (
    children && (
      <StyledGradientText {...otherProps}>{children}</StyledGradientText>
    )
  )
}

export default GradientTextBanner
