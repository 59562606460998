import { Container } from '@mui/material'

import { getLocalAssetFromCDN } from '@helpers'

import {
  StyledAdvantagesHolder,
  StyledAdvantagesImageHolder,
  StyledAdvantagesSubtitle,
  StyledAdvantagesTextBlock,
  StyledAdvantagesTextHolder,
  StyledAdvantagesWrapper
} from './AdvantagesSection.styled'
import { StyledInfoTitle } from '@features/villaLanding/InfoTitle/InfoTitle.styled'
import { GradientTextBannerSubtitle } from '@features/villaLanding/GradientTextBanner/GradientTextBanner.styled'
import GradientTextBanner from '@features/villaLanding/GradientTextBanner'

const AdvantagesSection = () => {
  return (
    <Container>
      <StyledInfoTitle
        sx={{ marginBottom: '16px', minWidth: 'calc(50% - 8px)' }}
      >
        also included in the price
      </StyledInfoTitle>
      <StyledAdvantagesWrapper>
        <StyledAdvantagesHolder>
          <StyledAdvantagesImageHolder>
            <img src={getLocalAssetFromCDN('/assets/img/landing/adv-1.webp')} />
          </StyledAdvantagesImageHolder>

          <StyledAdvantagesTextHolder>
            <StyledAdvantagesTextBlock>
              <StyledAdvantagesSubtitle>
                2021 Land Rover Defender 110
              </StyledAdvantagesSubtitle>
              <GradientTextBanner
                // @ts-ignore
                sx={{ width: '100%' }}
              >
                <GradientTextBannerSubtitle>Value:</GradientTextBannerSubtitle>{' '}
                £75,000
              </GradientTextBanner>
            </StyledAdvantagesTextBlock>
          </StyledAdvantagesTextHolder>
        </StyledAdvantagesHolder>
      </StyledAdvantagesWrapper>
    </Container>
  )
}

export default AdvantagesSection
