import { useCallback } from 'react'

import Image from 'next/image'

import { Container } from '@mui/material'

import { getLocalAssetFromCDN } from '@helpers'

import { Button, Typography } from '@lib/ui'

import GradientTextBanner from '../GradientTextBanner'
import { StyledInfoTitle } from '../InfoTitle/InfoTitle.styled'
import { GradientTextBannerSubtitle } from '../GradientTextBanner/GradientTextBanner.styled'

import {
  StyledButtonHolder,
  StyledGeneralInfoWrapper,
  StyledImagesBlock,
  StyledInfoBlock,
  StyledInfoContent,
  StyledSquareItem,
  StyledSquareList,
  StyledSquareText,
  StyledSquareTextContent
} from './GeneralInfo.styled'
import { type IGeneralInfoProps } from './GeneralInfo.types'

const GeneralInfoSection = (props: IGeneralInfoProps) => {
  const { slugUrl } = props

  const handleOpenCompetitionDetails = useCallback(() => {
    window.location.href = `https://elitecompetitions.co.uk/competitions/${slugUrl}`
  }, [slugUrl])

  return (
    <Container>
      <StyledGeneralInfoWrapper>
        <StyledImagesBlock>
          <img src={getLocalAssetFromCDN('/assets/img/landing/col.webp')} />
        </StyledImagesBlock>

        <StyledInfoBlock>
          <StyledInfoContent>
            <StyledSquareList>
              <StyledSquareItem>
                <Image
                  src={getLocalAssetFromCDN('/assets/img/landing/bed.svg')}
                  alt="bed"
                  className="d-inline"
                  loading="lazy"
                  width={32}
                  height={32}
                />
                <StyledSquareTextContent>
                  5<StyledSquareText>bdrm</StyledSquareText>
                </StyledSquareTextContent>
              </StyledSquareItem>

              <StyledSquareItem>
                <Image
                  src={getLocalAssetFromCDN('/assets/img/landing/shower.svg')}
                  alt="bed"
                  className="d-inline"
                  loading="lazy"
                  width={32}
                  height={32}
                />
                <StyledSquareTextContent>
                  4<StyledSquareText>bath</StyledSquareText>
                </StyledSquareTextContent>
              </StyledSquareItem>

              <StyledSquareItem>
                <Image
                  src={getLocalAssetFromCDN('/assets/img/landing/wide.svg')}
                  alt="bed"
                  className="d-inline"
                  loading="lazy"
                  width={32}
                  height={32}
                />
                <StyledSquareTextContent>
                  2,694<StyledSquareText>sqft</StyledSquareText>
                </StyledSquareTextContent>
              </StyledSquareItem>

              <GradientTextBanner>
                <GradientTextBannerSubtitle>Value:</GradientTextBannerSubtitle>{' '}
                £900,000
              </GradientTextBanner>
            </StyledSquareList>

            <Typography fontVariant="body-1" weight="regular">
              Own this brand-new fully furnished mansion in the Lancashire
              countryside! This stunning new property is designed for dream
              living, featuring a showstopping open-plan kitchen and living
              space, perfect for festive gatherings and celebrations. With 5
              luxurious bedrooms, 4 bathrooms, an elegant office, a huge garden,
              and top-of-the-line Lutron lighting, no expense has been spared in
              creating this ultimate retreat. Plus, £30,000 worth of furniture.
              You&apos;ll wake up to breathtaking rural views front and back,
              adding a touch of magic to every morning.
            </Typography>

            <StyledButtonHolder>
              <Button
                variant="primary"
                width="100%"
                onClick={handleOpenCompetitionDetails}
              >
                Enter now
              </Button>
            </StyledButtonHolder>
          </StyledInfoContent>
        </StyledInfoBlock>
      </StyledGeneralInfoWrapper>
    </Container>
  )
}

export default GeneralInfoSection
