import { useCallback } from 'react'

import Marquee from 'react-fast-marquee'

import { Button } from '@lib/ui'

import {
  StyledWalkLaneContainer,
  StyledWalkLaneText,
  StyledWrapper
} from './WalkLane.styled'
import { type IWalkLaneProps } from './WalkLane.types'

const WalkLane = (props: IWalkLaneProps) => {
  const { slugUrl } = props

  const handleOpenCompetitionDetails = useCallback(() => {
    window.location.href = `https://elitecompetitions.co.uk/competitions/${slugUrl}`
  }, [slugUrl])

  return (
    <StyledWrapper>
      <Marquee
        autoFill
        gradient
        gradientWidth="100%"
        speed={20}
        direction="left"
        gradientColor="transparent"
        pauseOnHover
      >
        <StyledWalkLaneContainer>
          <StyledWalkLaneText>Own your luxury house!</StyledWalkLaneText>

          <Button
            variant="primary"
            width="fit-content"
            onClick={handleOpenCompetitionDetails}
          >
            ENTER
          </Button>
        </StyledWalkLaneContainer>
      </Marquee>
    </StyledWrapper>
  )
}

export default WalkLane
