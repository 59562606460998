import { styled } from '@mui/material'

export const StyledHolder = styled('div')`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100px;
  color: #fff;
  display: flex;
  justify-content: center;
  z-index: 100;
  align-items: center;
`
