import { useRef } from 'react'

import VillaMainSection from '@features/villaLanding/MainSection'
import GeneralInfoSection from '@features/villaLanding/GeneralInfo'
import FixedVillaTimer from '@features/villaLanding/FixedVillaTimer'
import Carousel from '@features/villaLanding/Carousel'
import MapSection from '@features/villaLanding/MapSection'
import WinnersSection from '@features/villaLanding/WinnersSection'
import WalkLane from '@features/villaLanding/WalkLane'
import AdvantagesSection from '@features/villaLanding/AdvantagesSection'

const slugUrl = 'pound1000000-house-pound1000000-instawins-7747'

const VillaPage = () => {
  const firstScreenRef = useRef<HTMLDivElement | null>(null)

  return (
    <>
      <div ref={firstScreenRef}>
        <VillaMainSection slugUrl={slugUrl} />
      </div>

      <GeneralInfoSection slugUrl={slugUrl} />

      <AdvantagesSection />

      <Carousel />

      <WalkLane slugUrl={slugUrl} />

      <MapSection />

      <WinnersSection />

      <FixedVillaTimer screenRef={firstScreenRef} />
    </>
  )
}

export default VillaPage
