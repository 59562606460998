import { type ButtonHTMLAttributes } from 'react'

import { css, styled } from '@mui/material'

export const StyledMainImage = styled('div')`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: -1;

  video {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: var(--Neutral9);
    opacity: 0.4;
    z-index: 1;
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

export const StyledMainGradientText = styled('div')`
  font-family: 'Orbitron';
  display: flex;
  justify-content: center;
  background: linear-gradient(91.46deg, #e1010b 0%, #480000 100%);
  font-size: 48px;
  line-height: 56px;
  padding: 2px 8px;
  text-transform: uppercase;
  font-weight: 700;
  border-radius: 12px;
  letter-spacing: 1px;

  @media screen and (max-width: 992px) {
    font-size: 36px;
    line-height: 44px;
  }
`

export const StyledCarouselArrow = styled('div', {
  shouldForwardProp: propName => propName !== 'isRight'
})<
  {
    isRight?: boolean
  } & ButtonHTMLAttributes<HTMLDivElement>
>`
  position: absolute;
  top: 50%;
  width: 48px;
  height: 48px;
  border: 1px solid rgba(240, 240, 240, 0.6);
  background: var(--Neutral2);
  border-radius: 6px;
  z-index: 100;
  cursor: pointer;

  &:hover {
    background: rgba(240, 240, 240, 0.6);
  }

  & img {
    width: 32px;
    height: 32px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  ${({ isRight = false }) => {
    if (isRight) {
      return css`
        right: 10px;

        & img {
          transform: translate(-50%, -50%) rotate(180deg);
        }
      `
    } else {
      return css`
        left: 10px;
      `
    }
  }}
`

export const StyledContentBlock = styled('div')`
  text-align: center;
  font-family: 'Orbitron';
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  max-width: 1046px;
  display: flex;
  gap: 16px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 48px;
  line-height: 56px;
  font-weight: 600;
  padding: 0 12px;

  @media screen and (max-width: 992px) {
    font-size: 36px;
    line-height: 44px;
  }
`

export const StyledSliderImage = styled('div')`
  width: 100%;
  height: 100%;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

export const StyledSliderImageWithMob = styled(StyledSliderImage)`
  & img {
    @media screen and (max-width: 800px) {
      & {
        display: none;
      }

      &:nth-of-type(2) {
        display: block;
      }
    }
  }
`
