import { type MainSectionTabs } from '../MainSection/MainSection.types'

import {
  StyledMainTabsHolder,
  StyledTabItem,
  StyledTabs
} from './MainTabButtons.styled'
import { type IMainTabButtonsProps } from './MainTabButtons.types'

const TabsData: { title: string; id: MainSectionTabs }[] = [
  {
    title: 'Tour',
    id: 'Tab1'
  },
  {
    title: 'Gallery',
    id: 'Tab2'
  }
]

const MainTabButtons = (props: IMainTabButtonsProps) => {
  const { currentTab, setCurrentTab } = props

  return (
    <StyledMainTabsHolder>
      <StyledTabs>
        {TabsData.map(({ id, title }) => {
          return (
            <StyledTabItem
              key={id}
              isActive={id === currentTab}
              onClick={() => {
                setCurrentTab(id)
              }}
            >
              {title}
            </StyledTabItem>
          )
        })}
      </StyledTabs>
    </StyledMainTabsHolder>
  )
}

export default MainTabButtons
